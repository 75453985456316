const Header = ({ navLinks, socialLinks }) => (
  <Headroom>
    <header className="top-0 left-0 right-0 z-999 pv3 black">
      <div className="container flex justify-end">
        <a href="#main-content" className="sr-only">
          Skip Navigation
        </a>

        <BurgerAndOverlay navLinks={navLinks} socialLinks={socialLinks} />
      </div>
    </header>
  </Headroom>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import Headroom from 'react-headroom'

import BurgerAndOverlay from '../components/BurgerAndOverlay'

export default Header
