const Base = ({ children }) => (
  <StaticQuery
    query={graphql`
      query BaseQuery {
        site {
          siteMetadata {
            description
            language
            locale
            title
            siteUrl
          }
        }
        allLinksNavigationYaml {
          edges {
            node {
              url
              text
            }
          }
        }
        allLinksSocialYaml {
          edges {
            node {
              href
              srText
              fontSize
            }
          }
        }
      }
    `}
    // query BaseQuery {
    //   site {
    //     siteMetadata {
    //       description
    //       language
    //       locale
    //       title
    //       twitterHandle
    //       siteUrl
    //     }
    //   }
    //   allLinksNavigationYaml {
    //     edges {
    //       node {
    //         url
    //         text
    //       }
    //     }
    //   }
    //   allLinksSocialYaml {
    //     edges {
    //       node {
    //         href
    //         srText
    //         fontSize
    //       }
    //     }
    //   }
    // }
    render={data => (
      <Fragment>
        <SiteMetadata site={data.site.siteMetadata} />
        <Header
          navLinks={data.allLinksNavigationYaml.edges}
          socialLinks={data.allLinksSocialYaml.edges}
        />
        {children}
        <Footer />
        <BasicStructuredData site={data.site.siteMetadata} />
      </Fragment>
    )}
  />
)

/*
 *
 * Global styles
 * 
 */

import '../styles/index.css'

/*
 *
 * Metadata
 * 
 */

// See: https://github.com/nfl/react-helmet + https://megatags.co + https://gethead.info

import siteImage from '../images/portrait/patrick-raftery-headshot-2018-1-small.jpg'

const SiteMetadata = ({ site }) => (
  <Helmet>
    {/* HTML language */}
    <html itemScope itemType="http://schema.org/WebPage" lang={site.language} />

    {/* Title comes first (meta charset and viewport are automatically included) */}
    <title itemProp="name" lang={site.language}>
      {site.title}
    </title>

    {/* Search engine */}
    <meta name="description" content={site.description} />
    <meta name="image" content={site.siteUrl + siteImage.replace(`js/../`, ``)} />
    <link rel="canonical" href={site.siteUrl} />

    {/* Preconnect to CloudFlare CDN (for GSAP) */}
    <link rel="preconnect" href="https://cdnjs.cloudflare.com" />

    {/* Google fonts */}
    <link
      href="https://fonts.googleapis.com/css?family=Playfair+Display"
      rel="stylesheet"
    />

    {/* Schema.org for Google */}
    <meta itemProp="name" content={site.title} />
    <meta itemProp="description" content={site.description} />
    <meta
      itemProp="image"
      content={site.siteUrl + siteImage.replace(`js/../`, ``)}
    />

    {/* Twitter */}
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={site.title} />
    <meta name="twitter:description" content={site.description} />
    <meta
      name="twitter:image:src"
      content={site.siteUrl + siteImage.replace(`js/../`, ``)}
    />

    {/* Open Graph general (Facebook, Pinterest, Slack & Google+) */}
    <meta property="og:title" content={site.title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={site.siteUrl} />
    <meta
      property="og:image"
      content={site.siteUrl + siteImage.replace(`js/../`, ``)}
    />
    <meta property="og:description" content={site.description} />
    <meta property="og:site_name" content={site.title} />
    <meta property="og:locale" content={site.locale} />

    {/* Non-essential, but required for analytics */}
    {site.facebookAppId && (
      <meta property="fb:app_id" content={site.facebookAppId} />
    )}
    {site.twitterHandle && <meta name="twitter:site" content={site.twitterHandle} />}
  </Helmet>
)

/*
 *
 * Basic Structured Data
 * 
 */

const BasicStructuredData = ({ site }) => {
  const structuredData = `{
    "@context": "http://schema.org",
    "@type": "Person",
    "email": "mailto:info@jpatrickraftery.com",
    "image": "${site.siteUrl + siteImage.replace(`js/../`, ``)}",
    "jobTitle": "Opera Singer & Voice Teacher",
    "name": "J. Patrick Raftery",
    "url": "${site.siteUrl}",
    "sameAs": [
      "https://www.facebook.com/jpatrick.raftery",
      "https://twitter.com/jpraftery",
      "https://www.youtube.com/channel/UCvfXuwaXn9QWDdbBvy-uhSQ"
    ]
  }`

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: structuredData }}
    />
  )
}

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Header from '../sections/Header'
import Footer from '../sections/Footer'

export default Base
