const Footer = () => (
  <footer className="footer-grid container bg-white pv4 tc lh-tall f6">
    <Copyright />
  </footer>
)

/*
 *
 * Copyright
 * 
 */

const Copyright = () => (
  <Fragment>
    <p>&copy; {new Date().getFullYear()} J. Patrick Raftery</p>

    <p>
      Built with <span className="dark-red">♥</span> by&nbsp;
      <Anchor href="https://www.michaeluloth.com" className="link-inline">
        Michael Uloth
      </Anchor>
    </p>
  </Fragment>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'

import Anchor from '../components/Anchor'

export default Footer
