class BurgerAndOverlay extends Component {
  state = { menuOpen: false }

  componentDidMount = () => {
    // Bind modal to appElement (http://reactcommunity.org/react-modal/accessibility/)
    Modal.setAppElement(`#___gatsby`)
  }

  handleBurgerClick = () => {
    const { menuOpen } = this.state

    if (menuOpen) this.closeMenu()
    else this.openMenu()
  }

  openMenu = () => {
    this.setState({ menuOpen: true })
    noScroll.on()
  }

  closeMenu = () => {
    noScroll.off()
    this.setState({ menuOpen: false })
  }

  render() {
    const { menuOpen } = this.state
    const { navLinks, socialLinks } = this.props

    return (
      <Fragment>
        <button onClick={this.handleBurgerClick} className="link-inline f4">
          Menu
        </button>

        <Modal
          isOpen={menuOpen}
          onRequestClose={this.handleBurgerClick}
          closeTimeoutMS={0} // match exit animation timing
          overlayClassName="menu-modal-overlay fixed fill"
          className="menu-modal-content w-100 h-100 overflow-auto scrolling-touch bg-near-white"
        >
          <MenuContent
            navLinks={navLinks}
            socialLinks={socialLinks}
            closeMenu={this.handleBurgerClick}
          />
        </Modal>
      </Fragment>
    )
  }
}

/*
 *
 * Menu Content
 * 
 */

const MenuContent = ({ navLinks, socialLinks, closeMenu }) => (
  <aside onClick={closeMenu} onKeyPress={closeMenu} className="container pt3">
    <div className="tr">
      <button className="link-inline f4">Close</button>
    </div>

    <nav aria-label="Site navigation" className="pt3">
      <ul>
        {navLinks.map((link, i) => {
          return (
            <li key={i} className="mt3">
              <Link
                to={link.node.url}
                exact={true} // Prevents the home link from appearing active on every page
                className="section-heading link-inline di lh-copy"
              >
                {link.node.text}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>

    <SocialLinks links={socialLinks} />
  </aside>
)

/*
 *
 * Social Links
 *
 */

const SocialLinks = ({ links }) => (
  <nav aria-label="Social media links" className="mt4 pv5">
    <ul className="flex">
      {links.map((link, i) => <SocialLink key={i} link={link.node} />)}
    </ul>
  </nav>
)

const SocialLink = ({ link }) => {
  let icon = facebook
  if (link.srText === `Twitter`) icon = twitter
  if (link.srText === `YouTube`) icon = youtube

  return (
    <li className="flex-none flex mr3" style={{ width: `2.75em`, height: `2.75em` }}>
      <Anchor
        href={link.href}
        srText={`Follow on ${link.srText}`}
        className="link flex justify-center items-center w-100"
        style={{ fontSize: link.fontSize, padding: 0 }}
      >
        <Icon svg={icon} style={{ height: `auto` }} />
      </Anchor>
    </li>
  )
}

/*
 *
 * Imports & Exports
 * 
 */

import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import Modal from 'react-modal'
import noScroll from 'no-scroll'

import Anchor from '../components/Anchor'
import Icon from '../components/Icon'

import facebook from '../svg/facebook-f.svg'
import twitter from '../svg/twitter.svg'
import youtube from '../svg/youtube.svg'

export default BurgerAndOverlay
